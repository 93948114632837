@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7a7f9d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2E2E2E;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #2E2E2E;
}

.removeLink {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.legend-container {
  position: absolute;
  bottom: 200px;
  right: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
}
.mainContainer {
  flex: 1;
}

/* Add this to your CSS */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: spin 4s linear infinite; /* Adjust the duration as needed */
}


/* Add this to your CSS */
@keyframes moveRightToLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.moving-list {
  animation: moveRightToLeft 5s linear infinite; /* Adjust the duration and timing function as needed */
}


/* Add this to your CSS */
@keyframes moveRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.moving-list-container {
  display: flex;
  overflow: hidden;
  animation: moveRightToLeft 10s linear infinite; /* Adjust the duration as needed */
}




